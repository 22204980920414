// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-award-js": () => import("./../../../src/pages/award.js" /* webpackChunkName: "component---src-pages-award-js" */),
  "component---src-pages-case-study-action-figures-online-studio-js": () => import("./../../../src/pages/case-study/action-figures-online-studio.js" /* webpackChunkName: "component---src-pages-case-study-action-figures-online-studio-js" */),
  "component---src-pages-case-study-custom-clothing-design-ecommerce-js": () => import("./../../../src/pages/case-study/custom-clothing-design-ecommerce.js" /* webpackChunkName: "component---src-pages-case-study-custom-clothing-design-ecommerce-js" */),
  "component---src-pages-case-study-dental-health-care-website-js": () => import("./../../../src/pages/case-study/dental-health-care-website.js" /* webpackChunkName: "component---src-pages-case-study-dental-health-care-website-js" */),
  "component---src-pages-case-study-ecommerce-of-minimalist-products-js": () => import("./../../../src/pages/case-study/ecommerce-of-minimalist-products.js" /* webpackChunkName: "component---src-pages-case-study-ecommerce-of-minimalist-products-js" */),
  "component---src-pages-case-study-food-company-ecommerce-store-js": () => import("./../../../src/pages/case-study/food-company-ecommerce-store.js" /* webpackChunkName: "component---src-pages-case-study-food-company-ecommerce-store-js" */),
  "component---src-pages-case-study-indo-american-dating-platform-js": () => import("./../../../src/pages/case-study/indo-american-dating-platform.js" /* webpackChunkName: "component---src-pages-case-study-indo-american-dating-platform-js" */),
  "component---src-pages-case-study-luxury-real-estatemedia-managements-js": () => import("./../../../src/pages/case-study/luxury-real-estatemedia-managements.js" /* webpackChunkName: "component---src-pages-case-study-luxury-real-estatemedia-managements-js" */),
  "component---src-pages-case-study-medications-tracker-js": () => import("./../../../src/pages/case-study/medications-tracker.js" /* webpackChunkName: "component---src-pages-case-study-medications-tracker-js" */),
  "component---src-pages-case-study-online-store-for-tablets-stylus-js": () => import("./../../../src/pages/case-study/online-store-for-tablets-stylus.js" /* webpackChunkName: "component---src-pages-case-study-online-store-for-tablets-stylus-js" */),
  "component---src-pages-case-study-real-estate-buy-sell-rent-platform-js": () => import("./../../../src/pages/case-study/real-estate-buy-sell-rent-platform.js" /* webpackChunkName: "component---src-pages-case-study-real-estate-buy-sell-rent-platform-js" */),
  "component---src-pages-case-study-school-bus-pass-idcards-management-system-js": () => import("./../../../src/pages/case-study/school-bus-pass-idcards-management-system.js" /* webpackChunkName: "component---src-pages-case-study-school-bus-pass-idcards-management-system-js" */),
  "component---src-pages-case-study-smart-notes-and-puzzles-platform-js": () => import("./../../../src/pages/case-study/smart-notes-and-puzzles-platform.js" /* webpackChunkName: "component---src-pages-case-study-smart-notes-and-puzzles-platform-js" */),
  "component---src-pages-case-study-social-media-for-cigar-enthusiasts-js": () => import("./../../../src/pages/case-study/social-media-for-cigar-enthusiasts.js" /* webpackChunkName: "component---src-pages-case-study-social-media-for-cigar-enthusiasts-js" */),
  "component---src-pages-case-study-travel-hospitality-deals-platform-js": () => import("./../../../src/pages/case-study/travel-hospitality-deals-platform.js" /* webpackChunkName: "component---src-pages-case-study-travel-hospitality-deals-platform-js" */),
  "component---src-pages-case-study-truck-drivers-security-app-js": () => import("./../../../src/pages/case-study/truck-drivers-security-app.js" /* webpackChunkName: "component---src-pages-case-study-truck-drivers-security-app-js" */),
  "component---src-pages-case-study-virtual-interior-design-lab-js": () => import("./../../../src/pages/case-study/virtual-interior-design-lab.js" /* webpackChunkName: "component---src-pages-case-study-virtual-interior-design-lab-js" */),
  "component---src-pages-decodeup-globe-presents-js": () => import("./../../../src/pages/decodeup-globe-presents.js" /* webpackChunkName: "component---src-pages-decodeup-globe-presents-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-opening-js": () => import("./../../../src/pages/job-opening.js" /* webpackChunkName: "component---src-pages-job-opening-js" */),
  "component---src-pages-our-process-js": () => import("./../../../src/pages/our-process.js" /* webpackChunkName: "component---src-pages-our-process-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sample-js": () => import("./../../../src/pages/sample.js" /* webpackChunkName: "component---src-pages-sample-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-8-tech-trends-2021-that-will-be-game-changers-for-the-businesses-js": () => import("./../../../src/templates/blog/8-tech-trends-2021-that-will-be-game-changers-for-the-businesses.js" /* webpackChunkName: "component---src-templates-blog-8-tech-trends-2021-that-will-be-game-changers-for-the-businesses-js" */),
  "component---src-templates-blog-digital-transformation-for-retail-supply-chain-js": () => import("./../../../src/templates/blog/digital-transformation-for-retail-supply-chain.js" /* webpackChunkName: "component---src-templates-blog-digital-transformation-for-retail-supply-chain-js" */),
  "component---src-templates-blog-how-ai-is-transforming-ediscovery-js": () => import("./../../../src/templates/blog/how-ai-is-transforming-ediscovery.js" /* webpackChunkName: "component---src-templates-blog-how-ai-is-transforming-ediscovery-js" */),
  "component---src-templates-blog-how-iot-technology-is-a-blessing-for-the-businesses-of-today-js": () => import("./../../../src/templates/blog/how-iot-technology-is-a-blessing-for-the-businesses-of-today_.js" /* webpackChunkName: "component---src-templates-blog-how-iot-technology-is-a-blessing-for-the-businesses-of-today-js" */),
  "component---src-templates-blog-how-to-build-an-effective-enterprise-website-js": () => import("./../../../src/templates/blog/how-to-build-an-effective-enterprise-website.js" /* webpackChunkName: "component---src-templates-blog-how-to-build-an-effective-enterprise-website-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-main-principles-of-the-e-commerce-web-design-js": () => import("./../../../src/templates/blog/main-principles-of-the-e-commerce-web-design.js" /* webpackChunkName: "component---src-templates-blog-main-principles-of-the-e-commerce-web-design-js" */),
  "component---src-templates-blog-seven-philosophies-for-client-satisfaction-at-decodeup-js": () => import("./../../../src/templates/blog/seven-philosophies-for-client-satisfaction-at-decodeup.js" /* webpackChunkName: "component---src-templates-blog-seven-philosophies-for-client-satisfaction-at-decodeup-js" */),
  "component---src-templates-blog-the-power-of-teamwork-for-increased-productivity-js": () => import("./../../../src/templates/blog/the-power-of-teamwork-for-increased-productivity.js" /* webpackChunkName: "component---src-templates-blog-the-power-of-teamwork-for-increased-productivity-js" */),
  "component---src-templates-blog-top-ux-principles-for-a-successful-business-website-js": () => import("./../../../src/templates/blog/top-ux-principles-for-a-successful-business-website.js" /* webpackChunkName: "component---src-templates-blog-top-ux-principles-for-a-successful-business-website-js" */),
  "component---src-templates-blog-what-makes-a-website-effective-3-reasons-ui-design-improves-conversion-rates-js": () => import("./../../../src/templates/blog/what-makes-a-website-effective_-3-reasons-ui-design-improves-conversion-rates.js" /* webpackChunkName: "component---src-templates-blog-what-makes-a-website-effective-3-reasons-ui-design-improves-conversion-rates-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/case-studies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-custom-web-applications-js": () => import("./../../../src/templates/custom-web-applications.js" /* webpackChunkName: "component---src-templates-custom-web-applications-js" */),
  "component---src-templates-digital-marketing-js": () => import("./../../../src/templates/digital-marketing.js" /* webpackChunkName: "component---src-templates-digital-marketing-js" */),
  "component---src-templates-graphic-design-js": () => import("./../../../src/templates/graphic-design.js" /* webpackChunkName: "component---src-templates-graphic-design-js" */),
  "component---src-templates-mobile-applications-js": () => import("./../../../src/templates/mobile-applications.js" /* webpackChunkName: "component---src-templates-mobile-applications-js" */),
  "component---src-templates-portfolios-by-tag-js": () => import("./../../../src/templates/portfolios-by-tag.js" /* webpackChunkName: "component---src-templates-portfolios-by-tag-js" */),
  "component---src-templates-single-job-opening-js": () => import("./../../../src/templates/single-job-opening.js" /* webpackChunkName: "component---src-templates-single-job-opening-js" */),
  "component---src-templates-single-portfolio-js": () => import("./../../../src/templates/single-portfolio.js" /* webpackChunkName: "component---src-templates-single-portfolio-js" */),
  "component---src-templates-single-technology-js": () => import("./../../../src/templates/single-technology.js" /* webpackChunkName: "component---src-templates-single-technology-js" */),
  "component---src-templates-technologies-js": () => import("./../../../src/templates/technologies.js" /* webpackChunkName: "component---src-templates-technologies-js" */),
  "component---src-templates-verticals-js": () => import("./../../../src/templates/verticals.js" /* webpackChunkName: "component---src-templates-verticals-js" */),
  "component---src-templates-website-design-development-js": () => import("./../../../src/templates/website-design-development.js" /* webpackChunkName: "component---src-templates-website-design-development-js" */)
}

